<template>
  <base-layout class="app--tradein">
    <v-layout>
      <menu-main-menu v-if="isLoggedIn" />
      <v-main>
        <slot />
      </v-main>
    </v-layout>
    <template #after-main>
      <!-- @vue-ignore -->
      <!--      <NuxtNotifications-->
      <!--        position="top right"-->
      <!--        width="500"-->
      <!--        pause-on-hover-->
      <!--      />-->
    </template>
  </base-layout>
</template>

<script setup lang="ts">
const isLoggedIn = ref<boolean>(false);
const authStore = useAuthStore();
watchEffect(() => {
  isLoggedIn.value = authStore.isLoggedIn;
});
</script>
